<!-- role-edit -->
<template>
  <el-dialog
    class="role-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="role-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="角色名称:" prop="name">
        <el-input v-model.trim="model.name" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="角色状态:" prop="status">
        <el-radio-group v-model="model.status">
          <el-radio
            v-for="item in roleStatusList"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label="业务范围:" prop="bizRange">
        <el-radio-group v-model="model.bizRange">
          <el-radio
            v-for="item in roleBizRangeList"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label="数据权限:" prop="dataPermission">
        <el-radio-group v-model="model.dataPermission">
          <el-radio
            v-for="item in roleDataPermissionList"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label="角色权限:" prop="resourceIds">
        <resource
          v-model="model.resourceIds"
          type="tree"
          :filterable="false"
          :max-height="260"
        />
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="role-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消
        </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
          >确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import Resource from '../../../../components/resource';
  import roleStatus from '../../../../enum/role-status';
  import roleBizRange from '../../../../enum/role-biz-range.js';
  import roleDataPermission from '../../../../enum/role-data-permission';
  import { getRole, addRole, updateRole } from '../../../../api/auth/role';

  export default {
    name: 'role-edit',
    components: {
      Resource,
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
      // 角色 id
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        // 当前编辑的角色对象
        role: null,
        model: null,
        rules: {
          name: [{ required: true, message: '请填写', trigger: 'change' }],
          status: [{ required: true, message: '请选择', trigger: 'change' }],
          bizRange: [{ required: true, message: '请选择', trigger: 'change' }],
          dataPermission: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          resourceIds: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
        },
        loading: false,
        saving: false,
      };
    },
    computed: {
      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.id;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}角色`;
      },
      // 角色状态列表
      roleStatusList() {
        const { map } = roleStatus;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
      // 角色业务范围列表
      roleBizRangeList() {
        const { map } = roleBizRange;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
      // 角色数据权限列表
      roleDataPermissionList() {
        const { map } = roleDataPermission;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const {
          name = '',
          status = roleStatus.enum.enabled,
          bizRange = roleBizRange.enum.all,
          dataPermission = roleDataPermission.enum.self,
          resourceIds = [],
        } = data || {};

        this.model = {
          name,
          status,
          bizRange,
          dataPermission,
          resourceIds,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.id,
        };
        const method = this.isEdit ? updateRole : addRole;
        const res = await method(params);

        return !!res;
      },
      // 获取角色对象详情
      async getRole() {
        const res = await getRole(this.id);

        if (!res) return false;

        this.role = res.data;

        return true;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getRole();

        this.loading = false;

        if (!success) return;

        this.initModel(this.role);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.role = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .role-edit {
    .role-edit-form {
      .el-input {
        width: 100%;
      }
    }
  }
</style>
